



































































































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import FieldDateTime from '@/components/FieldDateTime.vue';
import FieldPhoto from '@/components/FieldPhoto.vue';
import FieldWashTag from '@/components/FieldWashTag.vue';
import DELIVERY from '@/graphql/queries/Delivery.graphql';
import TRAILERS from '@/graphql/queries/TrailerNames.graphql';
import TRUCKS from '@/graphql/queries/Trucks.graphql';
import DELIVERY_COMPLETE from '@/graphql/mutations/DeliveryComplete.graphql';
import parseISO from 'date-fns/parseISO';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isSameDay from 'date-fns/isSameDay';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { required, decimal } from 'vuelidate/lib/validators';
import pluralNumber from '@/utils/pluralNumber';
import validationMessages from '@/utils/validationMessages';
import { CODES, DELIVERY_STATUSES } from '@/utils/constants';
import {
  Delivery,
  Trailer,
  Truck,
  DeliveryCompleteMutationVariables,
  WashTagInput,
  DeliveryQueryVariables,
} from '@/types/schema';
import { GraphQLError } from 'graphql';
import { ApolloQueryResult } from 'apollo-client';

export default Vue.extend({
  name: 'DeliveryComplete',
  components: {
    AppMain,
    AppForm,
    FieldDateTime,
    FieldPhoto,
    FieldWashTag,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        inTime: '',
        outTime: '',
        trailer: '',
        truck: '',
        totalWeight: '' as string | number,
        scaleTicketPhoto: '',
        washTag: {
          photoFront: '',
          photoBack: '',
          tagTime: '',
        },
      },
      delivery: {} as Delivery,
      trailers: [] as Trailer[],
      trucks: [] as Truck[],
    };
  },
  computed: {
    hasDelivery(): boolean {
      return this.delivery && !!this.delivery.id;
    },
    detentionTime(): string {
      const { appointmentTime } = this.delivery;
      const { outTime } = this.form;
      if (!outTime || !appointmentTime) return '';
      const difference = differenceInMinutes(
        parseISO(outTime),
        parseISO(appointmentTime)
      );
      const detention = difference - 120;
      if (detention <= 0) return '';
      const hours = Math.floor(detention / 60);
      const minutes = detention % 60;
      const h = pluralNumber(hours, 'hour');
      const m = pluralNumber(minutes, 'minute');
      return `${h} ${m}`;
    },
    washTag(): WashTagInput {
      const { photoFront, photoBack, tagTime } = this.form.washTag;
      const photos = [{ file: photoFront }, { file: photoBack }];
      const uploads = photos.filter((photo) => !!photo.file);
      return { uploads, tagTime };
    },
  },
  watch: {
    delivery(newValue) {
      if (newValue && newValue.truck) this.form.truck = newValue.truck.id;
      if (newValue && newValue.trailer) this.form.trailer = newValue.trailer.id;
    },
  },
  validations() {
    return {
      form: {
        inTime: { required },
        outTime: {
          required,
          afterTime: (value, { inTime }) =>
            !inTime || isAfter(parseISO(value), parseISO(inTime)),
        },
        trailer: { required },
        truck: { required },
        totalWeight: { required, decimal },
        washTag: {
          photoFront: { required },
          tagTime: { required },
        },
      },
    };
  },
  methods: {
    validationMessages,
    async completeDelivery() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      try {
        this.loading = true;
        this.errors = [];
        await this.$apollo.mutate({
          mutation: DELIVERY_COMPLETE,
          variables: {
            input: {
              deliveryId: this.delivery.id,
              trailerId: this.form.trailer,
              truckId: this.form.truck,
              inTime: this.form.inTime,
              outTime: this.form.outTime,
              totalWeight: this.form.totalWeight,
              scaleTicket: this.form.scaleTicketPhoto
                ? { file: this.form.scaleTicketPhoto }
                : undefined,
              washTag: this.washTag,
            },
          } as DeliveryCompleteMutationVariables,
        });
        this.$notify({
          text: 'Delivery complete!',
          type: 'success',
          duration: 6000,
        });
        this.doRedirect();
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
    doRedirect() {
      const backDate = this.$route.query.backDate;
      if (backDate) {
        this.$router.push({
          name: this.$route.meta.backTo.name,
          params: { date: backDate.toString() },
        });
      } else {
        this.$router.push({ name: this.$route.meta.backTo.name });
      }
    },
    beforeOutTime(date: string): boolean {
      const { outTime } = this.form;
      if (!outTime) return true;
      const parsedDate = parseISO(date);
      const parsedOutTime = parseISO(outTime);
      const sameDay = isSameDay(parsedDate, parsedOutTime);
      const beforeDay = isBefore(parsedDate, parsedOutTime);
      return sameDay || beforeDay;
    },
    afterInTime(date: string): boolean {
      const { inTime } = this.form;
      if (!inTime) return true;
      const parsedDate = parseISO(date);
      const parsedInTime = parseISO(inTime);
      const sameDay = isSameDay(parsedDate, parsedInTime);
      const afterDay = isAfter(parsedDate, parsedInTime);
      return sameDay || afterDay;
    },
  },
  apollo: {
    delivery: {
      query: DELIVERY,
      variables(): DeliveryQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<{ delivery: Delivery }>) {
        if (!data || !data.delivery) return;
        if (data.delivery.status === DELIVERY_STATUSES.COMPLETED) {
          this.doRedirect();
        }
      },
      error({ graphQLErrors }) {
        const [gqlError] = graphQLErrors;
        if (gqlError?.extensions?.code === CODES.NOT_FOUND) {
          this.doRedirect();
        }
      },
    },
    trailers: {
      query: TRAILERS,
    },
    trucks: {
      query: TRUCKS,
    },
  },
});
